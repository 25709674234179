<template>
  <div>
    <el-form :model="form" label-width="90px" style="padding:0px 83px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="姓名">
            <el-input v-model="form.user_name" placeholder="请填写姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="性别">
            <el-radio-group v-model="form.is_sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="民族">
            <el-radio-group v-model="form.minzu">
              <el-radio :label="1">汉</el-radio>
              <el-radio :label="0">少数民族</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="科类">
            <el-radio-group v-model="form.is_wenli">
              <el-radio :label="1">文科</el-radio>
              <el-radio :label="2">理科</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="高考成绩">
            <el-input v-model="form.score" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="语文">
            <el-input v-model="form.chinese" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数学">
            <el-input v-model="form.math" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="英语">
            <el-input v-model="form.english" placeholder="输入分数" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="手机号">
            <el-input v-model="form.mobile" placeholder="输入手机号" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="所在省市">
            <el-input v-model="form.province" placeholder="河南省" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="市">
            <el-select v-model="form.city" placeholder="请选择" @change="selectCity($event)">
              <el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="县">
            <el-select v-model="form.district" placeholder="请选择" @change="selectSchool($event)">
              <el-option v-for="item in districtList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="就读中学">
            <el-select v-model="form.school" placeholder="请选择">
              <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="就读年级">
            <el-input v-model="form.grade" placeholder="年级" />
          </el-form-item>
        </el-col>
      </el-row>

      <div class="title" style="margin-bottom: 15px">意向省份</div>
      <el-form-item label="选择省份">
        <el-select v-model="form.like_city" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">排除省份</div>

      <el-form-item label="选择省份">
        <el-select v-model="form.no_like_city" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">特殊升学要求</div>
      <el-form-item label="选择">
        <el-select v-model="form.special_request" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in spSpecialty" :key="item.name" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.tssxbz" placeholder="备注" type="textarea" style="width: 300px; margin-bottom: 10px" />
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">喜欢专业</div>
      <el-form-item label="喜欢专业">
        <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容" v-model="form.like_sp" />
      </el-form-item>
      <div class="title" style="margin-bottom: 15px">排除专业</div>
      <el-form-item label="排除专业">
        <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容" v-model="form.no_like_sp" />
      </el-form-item>

      <el-form-item>
        <div style="
              background: blue;
              color: white;
              border-radius: 10px;
              padding: 2px 20px;
              font-size: 15px;
              display: block;
              border-radius: 60px;
              color: #fff;
              background-color: #409eff;
              border-color: #409eff;
              width: 101px;
              line-height: 27px;
            " @click="onSubmit">
          确认修改
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import height_school from "@/assets/data/height_school.js";
import city_data_array from "@/assets/data/city_data.js";
export default {
  name: "",
  data() {
    return {
      form: [],
      cityList: [],
      schoolList: [],
      districtList: [],
      spSpecialty: [
        { id: 0, name: "无" },
        { id: 1, name: "艺术类" },
        { id: 2, name: "体育类" },
        { id: 3, name: "军队院校" },
        { id: 4, name: "定向培养士官" },
        { id: 5, name: "公安院校" },
        { id: 6, name: "司法类" },
        { id: 7, name: "香港高校" },
        { id: 8, name: "澳门高校" },
        { id: 9, name: "定向就业" },
        { id: 10, name: "少数民族预科班" },
      ],
    };
  },
  props: ["userData"],
  computed: {},
  watch: {
    userData(item) {
      this.form = item;
    },
  },
  created() {
    this.cityList = height_school;
    this.city = city_data_array;

    this.form = this.userData;
  },
  methods: {
    selectCity(value) {
      let obj = {};
      obj = this.cityList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.district = null;
      this.districtList = obj.children;
    },
    selectSchool(value) {
      let obj = {};
      obj = this.districtList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.school = null;
      this.schoolList = obj.children;
    },
    onSubmit() {
      this.$fecth.post("user_info/updateUserInfo", this.form).then((res) => {
        if (res) {
          this.$emit("closeEdit", 1);
          this.$notify({
            showClose: true,
            message: "更新成功！",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
</style>
