<template>
  <div class="user_list_form">
    <div class="user_list">
      <div class="card" v-for="(item, i) in UserData" :key="i">
        <el-row type="flex" align="center">
          <el-col :span="4">
            <div class="img" @click="toUserInfo(item.id, item.score, item.is_wenli, item.user_name)">
              <el-avatar :size="50" :src="item.head_img" v-if="item.head_img">
              </el-avatar>
              <el-avatar v-else :size="50" src="">
              </el-avatar>
            </div>
          </el-col>
          <el-col :span="19" :offset="1">
            <div class="name" @click="toUserInfo(item.id, item.score, item.is_wenli, item.user_name)">
              <span>{{ item.user_name }}</span>
              <span>{{ item.is_wenli == 1 ? " 文科" : " 理科" }}</span>
              <span>{{ item.is_sex == 1 ? " 男" : " 女" }}</span>
              <br />
              <span>{{ item.school }}</span> <span>{{ item.score }}分</span>
            </div>
            <div class="edit" @click="edit(item)">编辑</div>
            <div v-for="(m, k) in ExpertList" :key="k">
              <span class="teacher" v-if="m.id == item.parent_id">所属导师：{{ m.name }}</span>
            </div>
            <div class="btn">
              <span @click="changeType(item.id)">添加标记</span>
              <span @click="editStatus(item.id)">发送给其他导师</span>
              <span @click="ToVolunteer(item)">志愿表</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisibles" title="发送给其他导师" width="700px">
      <el-radio-group v-model="ExpertName">
        <el-radio :label="item.id" :value="item.id" v-for="(item, k) in ExpertList" :key="k">{{ item.name }}</el-radio>
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="changeParent()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="700px">
      <editType :user_id="user_id" @close="close" />
    </el-dialog>

    <el-dialog :visible.sync="dialogEdit" title="修改个人信息" width="1180px" destroy-on-close>
      <editUser :userData=form @closeEdit="closeEdit" />
    </el-dialog>
  </div>
</template>

<script>
import editType from "./editType";
import editUser from "./editUser";

export default {
  name: "",
  props: ["UserData", "ExpertList"],
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      dialogEdit: false,
      ExpertName: null,
      type: 1,
      user_id: null,
      form: {},
    };
  },
  components: {
    editType,
    editUser,
  },

  methods: {
    close() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    toUserInfo(id, score, is_wenli, name) {
      this.$router.push(
        "/user_info?id=" +
        id +
        "&score=" +
        score +
        "&is_wenli=" +
        is_wenli +
        "&name=" +
        name
      );
    },

    ToVolunteer(item) {
      this.$router.push({
        path: "volunteer",
        query: {
          id: item.id,
          score: item.score,
          is_wenli: item.is_wenli,
          nav_id: 3
        },
      });
    },

    changeType(id) {
      this.user_id = id;
      this.dialogVisible = true;
    },
    editStatus(id) {
      this.user_id = id;
      this.dialogVisibles = true;
    },
    changeParent() {
      let data = {
        parent_id: this.ExpertName,
        user_id: this.user_id,
      };
      this.$fecth.post("expert/editParent", data).then((res) => {
        const { code } = res;
        if (code == 200) {
          this.dialogVisibles = false;
          this.$parent.getList();
        }
      });
    },
    edit(item) {
      this.form = item;
      this.dialogEdit = true;
    },
    closeEdit() {
      this.dialogEdit = false;
      this.$parent.getList();
    },
  },
};
</script>

<style scoped lang='less'>
.user_list {
  .card {
    background: white;
    margin-bottom: 12px;
    display: inline-block;
    vertical-align: top;
    height: 120px;
    margin-right: 5px;
    padding: 10px;
    width: 32.7%;

    .name {
      line-height: 150%;
    }

    .edit {
      color: #8f8e8e;
      position: absolute;
      right: 1px;
      top: 3px;
    }

    .btn {
      margin-top: 10px;

      span {
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        padding: 1px 7px;
        margin-right: 7px;
        font-size: 14px;
      }
    }
  }

  .teacher {
    font-size: 10px;
    border: 1px solid #02e44d;
    display: inline;
    padding: 1px 10px;
    border-radius: 10px;
    color: #02e44d;
  }
}
</style>

<style lang="less">
.user_list_form {
  .el-dialog__header {
    text-align: center;
    background: #5b97f9;
    border-radius: 0 0 50px 50px;
    width: 200px;
    height: 40px;
    margin: 0 auto;

    .el-dialog__title {
      color: white;
      line-height: 40px;
      font-size: 16px;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>