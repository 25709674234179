<template>
  <div class="page">
    <div class="main">
      <div style="height:500px;overflow-y:scroll;padding-right:30px">
        <el-form v-if="form" ref="form" :model="form" label-width="90px" style="margin-top: 30px" size="mini">
          <el-row>
            <el-col :span="6">
              <el-form-item label="姓名">
                <el-input v-model="form.user_name" placeholder="请填写姓名" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="性别">
                <el-radio-group v-model="form.is_sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="民族">
                <el-radio-group v-model="form.minzu">
                  <el-radio :label="1">汉</el-radio>
                  <el-radio :label="0">少数民族</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="科类">
                <el-radio-group v-model="form.is_wenli">
                  <el-radio :label="1">文科</el-radio>
                  <el-radio :label="2">理科</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="高考成绩">
                <el-input v-model="form.score" placeholder="输入分数" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="语文">
                <el-input v-model="form.chinese" placeholder="输入分数" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="数学">
                <el-input v-model="form.math" placeholder="输入分数" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="英语">
                <el-input v-model="form.english" placeholder="输入分数" />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="form.mobile" placeholder="输入手机号" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="所在省市">
                <el-input v-model="form.province" placeholder="河南省" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="市">
                <el-select v-model="form.city" placeholder="请选择" @change="selectCity($event)">
                  <el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="县">
                <el-select v-model="form.district" placeholder="请选择" @change="selectSchool($event)">
                  <el-option v-for="item in districtList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item label="就读中学">
                <el-select v-model="form.school" placeholder="请选择">
                  <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="就读年级">
                <el-input v-model="form.grade" placeholder="年级" />
              </el-form-item>
            </el-col>
          </el-row>

          <div class="title" style="margin-bottom: 15px">意向省份</div>
          <el-form-item label="选择省份">
            <el-select v-model="form.like_city" multiple placeholder="请选择" style="width: 480px">
              <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
              </el-option>
            </el-select>
          </el-form-item>

          <div class="title" style="margin-bottom: 15px">排除省份</div>

          <el-form-item label="选择省份">
            <el-select v-model="form.no_like_city" multiple placeholder="请选择" style="width: 480px">
              <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
              </el-option>
            </el-select>
          </el-form-item>

          <div class="title" style="margin-bottom: 15px">特殊升学要求</div>
          <el-form-item label="选择">
            <el-select v-model="form.special_request" multiple placeholder="请选择" style="width: 480px">
              <el-option v-for="item in spSpecialty" :key="item.name" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.tssxbz" placeholder="备注" type="textarea" style="width: 300px; margin-bottom: 10px" />
          </el-form-item>

          <div class="title" style="margin-bottom: 15px">喜欢专业</div>
          <el-form-item label="喜欢专业">
            <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容" v-model="form.like_sp" />
          </el-form-item>
          <div class="title" style="margin-bottom: 15px">排除专业</div>
          <el-form-item label="排除专业">
            <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容" v-model="form.no_like_sp" />
          </el-form-item>

        </el-form>
      </div>
      <div style="text-align: center;margin-top:20px">
        <el-button @click="onSubmit1()">取消添加</el-button>
        <el-button type="primary" @click="onSubmit">确认添加</el-button>
      </div>
    </div>

    <el-dialog title="提示" append-to-body :visible.sync="dialogVisible" width="680px">
      <el-table :data="UserData" style="width: 100%;height:400px;overflow:scroll">
        <el-table-column prop="user_name" label="姓名">
        </el-table-column>
        <el-table-column prop="is_wenli" label="科类">
          <template slot-scope="scope">
            {{scope.is_wenli==1?'文科':'理科'}}
          </template>
        </el-table-column>
        <el-table-column prop="score" label="分数">
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="eidt(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import height_school from "@/assets/data/height_school.js";
import city_data_array from "@/assets/data/city_data.js";

export default {
  name: "",
  data() {
    return {
      UserData: [],
      dialogVisible: false,
      tables: {
        total: 1,
      },
      form: {
        school: "",
        no_like_sp: "",
        like_sp: "",
        type: 5,
        minzu: 1,
        is_sex: 1,
        is_wenli: 1,
      },
      districtList: [],
      schoolList: [],
      cityList: [],
      city: [],
      spSpecialty: [
        { id: 0, name: "无" },
        { id: 1, name: "艺术类" },
        { id: 2, name: "体育类" },
        { id: 3, name: "军队院校" },
        { id: 4, name: "定向培养士官" },
        { id: 5, name: "公安院校" },
        { id: 6, name: "司法类" },
        { id: 7, name: "香港高校" },
        { id: 8, name: "澳门高校" },
        { id: 9, name: "定向就业" },
        { id: 10, name: "少数民族预科班" },
      ],
    };
  },
  created() {
    this.city = city_data_array;
    this.cityList = height_school;
  },

  methods: {
    eidt(item) {
      this.$router.push({
        path: "volunteer",
        query: {
          id: item.id,
          score: item.score,
          is_wenli: item.is_wenli,
        },
      });
    },
    getList() {
      let ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
      let datas = {
        order: "id",
        type: 5,
        parent_id: ExpertInfo.id,
      };

      this.$fecth.post("user/getData", datas).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.UserData = data.lists;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    onSubmit() {
      let ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
      this.form.parent_id = ExpertInfo.id;
      this.$fecth.post("user/add", this.form).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$notify({
            showClose: true,
            message: "添加成功",
            type: "success",
          });
          this.$emit("test", 1);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    onSubmit1() {
      this.$emit("test", 1);
    },
    selectCity(value) {
      let obj = {};
      obj = this.cityList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.district = null;
      this.districtList = obj.children;
    },
    selectSchool(value) {
      let obj = {};
      obj = this.districtList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.school = null;
      this.schoolList = obj.children;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>
