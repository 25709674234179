<template>
  <div>
    <el-radio-group v-model="type">
      <el-radio :label="'5'">自建用户</el-radio>
      <el-radio :label="'4'">一对一用户</el-radio>
      <el-radio :label="'3'">志愿审核用户</el-radio>
      <!-- <el-radio :label="'2'">测评</el-radio> -->
      <el-radio style="margin-top: 30px; margin-bottom: 50px" :label="'0'"
        >普通用户</el-radio
      >
    </el-radio-group>
    <br>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="editType" style="margin-left:500px">确 定</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "",
  props: ["user_id"],
  data() {
    return {
      type: "0",
    };
  },
  
  
  methods: {
    editType() {
      let data = {
        id: this.user_id,
        type: this.type,
      };
      this.$fecth.post("user/editType", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.$emit("close", "ture");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'>
</style>
